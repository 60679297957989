<template>
  <v-card
    class="warehouse-row d-flex flex-column flex-sm-row"
    :to="{
      name: 'WarehouseDetail',
      params: {
        warehouseSlug: warehouse.slug
      }
    }"
  >
    <div class="wh-content">
      <v-card-title class="pb-1 px-2">
        {{ warehouseName }}
      </v-card-title>
      <v-card-text class="text-body-2 px-2">
        {{
          $t(
            "navbar.address." + warehouse.address.addressTypeId + ".list",
            warehouse.address
          )
        }}
      </v-card-text>
      <v-card-actions>
        <router-link
          :aria-label="`Punto vendita - ${warehouse.slug}`"
          :title="`Punto vendita -  ${warehouse.slug}`"
          class="font-weight-semibold text-decoration-none primary--text"
          :to="{
            name: 'WarehouseDetail',
            params: {
              warehouseSlug: warehouse.slug
            }
          }"
        >
          {{ $t("storeLocator.goToDetail") }}
          <v-icon x-small color="primary">$chevronRight</v-icon>
        </router-link>
      </v-card-actions>
    </div>
    <v-spacer />
    <div
      v-if="warehouse.warehouseClass"
      class="warehouse-classes d-flex align-center"
    >
      <template v-for="(wClass, index) in warehouse.warehouseClass">
        <img
          :key="index"
          v-if="wClass.iconSource && wClass.iconSource !== ''"
          :src="wClass.iconSource"
          :alt="wClass.name"
          class="warehouse-class-img ml-4"
        />
      </template>
    </div>
  </v-card>
</template>
<style lang="scss">
.warehouse-row {
  border-bottom: 2px solid var(--v-grey-base) !important;
  .warehouse-classes {
    .warehouse-class-img {
      height: 60px;
      width: 60px;
    }
  }
}
</style>
<script>
import get from "lodash/get";

export default {
  name: "WarehouseRow",
  props: {
    warehouse: { type: Object },
    categorySlug: { type: String }
  },
  computed: {
    warehouseName() {
      return get(
        this.warehouse,
        "metaData.warehouse_detail.NAME",
        this.warehouse.name
      );
    }
  }
};
</script>
