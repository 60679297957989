<template>
  <div class="warehouse-province">
    <Breadcrumb :items="breadCrumbs" />

    <v-container>
      <ebsn-style :target="category" path="category_advanced.CSS_STYLE" />
      <ebsn-meta
        :target="category"
        path="category_info.TITLE"
        tag="h1"
        :default-value="category.name"
        :style="{ color: $ebsn.meta(category, 'category_info.COLOR') }"
      ></ebsn-meta>

      <v-row class="description-container">
        <v-col cols="12" md="6">
          <img class="static-map-generated" :src="staticMapSrc" alt="" />
        </v-col>
        <v-col cols="12" md="6">
          <ebsn-meta
            class="description"
            :target="category"
            path="category_info.DESCRIPTION"
            tag="div"
            @click="clicked"
          ></ebsn-meta>
        </v-col>
      </v-row>
      <div
        class="warehouse-list mt-5"
        v-for="warehouse in warehouseList"
        :key="warehouse.warehouseId"
      >
        <WarehouseRow :warehouse="warehouse" :categorySlug="category.slug" />
      </div>
    </v-container>
  </div>
</template>
<style lang="scss">
.static-map-generated {
  width: 100%;
  object-fit: contain;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    max-width: 100%;
    // align-self: center;
    margin-right: 0px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
  }
}
</style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import WarehouseRow from "@/components/store/WarehouseRow.vue";

import category from "~/mixins/category.js";
import { calculateBreadcrumbs } from "@/components/store/warehouseHelper";

import AddressService from "~/service/addressService";

import get from "lodash/get";

export default {
  components: {
    Breadcrumb,
    WarehouseRow
  },
  name: "WarehouseCooperative",

  mixins: [category],
  data() {
    return {
      warehouseList: []
    };
  },
  computed: {
    breadCrumbs() {
      return calculateBreadcrumbs(this.category.slug, "/area");
    },
    staticMapSrc() {
      // example url to compose
      // https://maps.googleapis.com/maps/api/staticmap?center=45.482934,9.158543&zoom=10&key=AIzaSyBAQCkIX0VQBb1Dg6jFjYrO8t-lA12mhy0&size=640x400&maptype=roadmap&markers=icon:https://www.iper.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.482934,9.158543&markers=icon:https://www.iper.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.451084,9.096755&markers=icon:https://www.iper.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.498495,9.236599&markers=icon:https://www.iper.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.468506,9.041534&markers=icon:https://www.iper.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.546263,8.879139&markers=icon:https://www.iper.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.550804,8.944681&markers=icon:https://www.iper.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.529814,9.217539&markers=icon:https://www.iper.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.448217,9.212212&markers=icon:https://www.iper.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.529916,9.506116
      let mapSrc = "https://maps.googleapis.com/maps/api/staticmap?";
      mapSrc += `center=${this.$ebsn.meta(
        this.category,
        "template_model.GSP_COORDINATES"
      )}`;
      mapSrc += "&zoom=8";
      mapSrc += `&key=${process.env.VUE_APP_GOOGLE_API_KEY}`;
      mapSrc += "&size=640x400";
      mapSrc += "&maptype=roadmap";
      this.warehouseList.forEach(warehouse => {
        if (
          warehouse.address &&
          warehouse.address.latitude &&
          warehouse.address.longitude
        ) {
          mapSrc += `&markers=icon:/img_layout/map_pins/${warehouse.storeId}.png|${warehouse.address.latitude},${warehouse.address.longitude}`;
        }
      });
      return mapSrc;
    },
    gpsProvinceCoordinates() {
      return get(this.category, "metaData.template_model.GSP_COORDINATES");
    }
  },
  methods: {
    async findWarehouse() {
      let warehousesId = get(
        this.category,
        "metaData.template_model.WAREHOUSE_ID"
      );
      if (warehousesId) {
        let data = await AddressService.findWarehouse({
          parent_warehouse_id: warehousesId
        });
        this.warehouseList = data.warehouses;
      }
    }
  },
  mounted() {
    this.findWarehouse();
  }
};
</script>
